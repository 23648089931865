import axios from "axios";
import { Config } from "../config/api";
import Registry from "./register_storage";

class HttpConnect {
  constructor() {
    this.registry = new Registry(window.localStorage);
    if (!HttpConnect.instance) {
      HttpConnect.instance = this;

      const cancelToken = axios.CancelToken;
      this.source = cancelToken.source();

      this.instance = axios.create({
        baseURL: this.getHost(Config.api_host),
        headers: {
          "Content-Type": "application/json",
        },
        timeout: 180000,
        withCredentials: true,
        cancelToken: this.source.token,
      });
    }
  }

  /**
   * generate correct api host, in case its not an URL attach that prefix to original domain
   * @param {string} api_host
   * @return {string}
   */
  getHost(api_host) {
    let host = api_host;
    try {
      new URL(host);
    } catch {
      host = `${window.location.origin}${host}`;
    }

    return host;
  }

  /**
   * Cancel all active requests
   */
  cancelRequest() {
    this.source.cancel("cancelled");

    // assign new token
    const cancelToken = axios.CancelToken;
    this.source = cancelToken.source();

    this.instance.defaults.cancelToken = this.source.token;
  }

  /**
   * Construct query strings
   * @param {string} baseUri
   * @param {object} params
   * @returns {string}
   */
  __buildUriWithParams(baseUri, params = {}) {
    const queryString = Object.keys(params)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join("&");

    return queryString ? `${baseUri}?${queryString}` : baseUri;
  }

  /**
   * Send a request to the server
   * @param {String} uri
   * @param {Object} config
   * @private
   */
  __fetch(uri, config = {}) {
    const token = this.registry.getItem("token");
    if (token) {
      if (!("headers" in config)) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }

    return new Promise((resolve, reject) =>
      this.instance.request({ ...config, url: uri }).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          if (axios.isCancel(error)) {
            reject({});
            return;
          }

          // for unauthorized requests send message through bus
          if (error.response && ~[401].indexOf(error.response.status) && !~["/login"].indexOf(uri)) {
            //PubSub.publish(AuthController.PUBSUB_TOPIC,
            //	new BusMessage(BusMessage.OK, "logout", false));
            return reject({ action: "logout" });
          }

          if (
            ~error.message.toLowerCase().indexOf("network error") ||
            ~error.message.toLowerCase().indexOf("timeout of ") ||
            ~[500].indexOf(error.response.status)
          ) {
            return reject({ status: "error", message: "Oops... Something wrong with API server." });
          }

          /*
          let message = "Oops something went wrong";
          if(error.response) {
            message = error.response.data.error.message;
          }
          */

          // PubSub.publish(HttpConnect.PUBSUB_TOPIC, new BusMessage(BusMessage.ERROR, uri, message));
          reject(error.response.data);
        },
      ),
    );
  }

  /**
   * Implement GET method
   *
   * @param {String} uri
   * @param {object} params
   * @returns {Promise}
   */
  async get(uri, params = {}) {
    const headers = {
      method: "GET",
    };

    const fullUri = this.__buildUriWithParams(uri, params);

    return await this.__fetch(fullUri, headers);
  }

  /**
   * Implement DELETE method
   *
   * @param {String} uri
   * @param {object} params
   * @returns {Promise}
   */
  async remove(uri, params) {
    const headers = {
      method: "DELETE",
    };

    const fullUri = this.__buildUriWithParams(uri, params);

    return await this.__fetch(fullUri, headers).catch();
  }

  /**
   * Implement POST method
   * @param {String} uri
   * @param {Object} data
   * @returns {Promise}
   */
  async post(uri, data) {
    const headers = {
      method: "POST",
      data: data,
    };

    return await this.__fetch(uri, headers);
  }

  /**
   * Implement POST method to download
   * @param {String} uri
   * @param {Object} data
   * @returns {Promise}
   */
  async post_download(uri, data) {
    const headers = {
      method: "POST",
      data: data,
      responseType: "arraybuffer",
    };

    return await this.__fetch(uri, headers);
  }

  /**
   * Implement PUT method
   * @param {String} uri
   * @param {Object} data
   * @returns {Promise}
   */
  async put(uri, data) {
    const headers = {
      method: "PUT",
      data: data,
    };

    return await this.__fetch(uri, headers);
  }

  /**
   * Implement DELETE method
   *
   * @param {String} uri
   * @param {object} params
   * @returns {Promise}
   */
  async delete(uri, params = {}) {
    const headers = {
      method: "DELETE",
    };

    const fullUri = this.__buildUriWithParams(uri, params);

    return await this.__fetch(fullUri, headers);
  }
}

const instance = new HttpConnect();
export default instance;
