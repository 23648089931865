import { useCallback, useLayoutEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { isUndefined } from "./common_utils";

// biome-ignore lint/style/useDefaultParameterLast: changing the order to the suggested breaks the app - this will need to be refactored later
// biome-ignore lint/style/useNamingConvention: this will be part of a larger refactor
export const useForm = (cb, initial_state = {}, validation) => {
  const [values, setValues] = useState(initial_state);
  const [errors, setErrors] = useState({});
  const intl = useIntl();

  /**
   * Handle text input.
   * @param {Event} e
   * @param {object} input
   */
  const onChange = (e, input) => {
    let elName;
    let elValue;
    if (isUndefined(input)) {
      elName = e.target.name;
      elValue = e.target.value;
    } else {
      elName = input.name;
      elValue = input.value;
    }

    setValues({ ...values, [elName]: elValue });
  };

  /**
   * Handle checkbox input.
   * @param {Event} e
   * @param {HTMLInputElement} input
   */
  const onSwitch = (_e, { name, value, checked }) => {
    setValues({ ...values, [name]: checked ? value : 0 });
  };

  /**
   * Handle form submission.
   * @param {React.FormEvent & { target: HTMLFormElement }} e
   * @param  {...any} args - passed to form callback
   */

  // biome-ignore lint/complexity/noExcessiveCognitiveComplexity: TODO refactor later
  const onSubmit = (e, ...args) => {
    e.preventDefault();

    // reset errors
    setErrors({});

    const formErrors = {};
    let firstItem;
    if (!e.target.checkValidity()) {
      for (const el of e.target.querySelectorAll(":invalid")) {
        // Drop old custom validation message so it doesn't get stuck:
        el.setCustomValidity("");

        if (!firstItem) {
          firstItem = el;
        }

        let errorAbbr;
        if (el.validity.valueMissing) {
          errorAbbr = "VALIDATION_VALUE_MISSING";
        } else if (el.validity.rangeUnderflow) {
          errorAbbr = "VALIDATION_RANGE_UNDERFLOW";
        } else if (el.validity.rangeOverflow) {
          errorAbbr = "VALIDATION_RANGE_OVERFLOW";
        } else if (el.validity.tooShort) {
          errorAbbr = "VALIDATION_TOO_SHORT";
        } else if (el.validity.typeMismatch) {
          // eslint-disable-next-line default-case
          switch (el.getAttribute("type")) {
            case "email": {
              errorAbbr = "VALIDATION_INVALID_EMAIL";
              break;
            }
            case "url": {
              errorAbbr = "VALIDATION_INVALID_URL";
              break;
            }
          }
        }

        const errorMessage = errorAbbr
          ? intl.formatMessage(
              {
                id: errorAbbr,
                defaultMessage: el.validationMessage,
              },
              {
                // biome-ignore lint/style/useNamingConvention: <server params>
                min_value: el.getAttribute("min"),
                // biome-ignore lint/style/useNamingConvention: <server params>
                max_value: el.getAttribute("max"),
                // biome-ignore lint/style/useNamingConvention: <server params>
                min_length: el.getAttribute("minlength"),
                // biome-ignore lint/style/useNamingConvention: <server params>
                value_length: el.value.toString().length,
              },
            )
          : el.validationMessage;

        // updage custom validity
        if (errorMessage) {
          el.setCustomValidity(errorMessage);

          formErrors[el.name] = el.validity.patternMismatch && el.title ? el.title : errorMessage;
        }
      }
    }

    const customErrors = validation(e, values);
    if (Object.keys(customErrors).length === 0 && Object.keys(formErrors).length === 0) {
      cb(values, ...args);
    } else {
      if (firstItem?.focus) {
        firstItem.focus();
      }
      setErrors(Object.assign(customErrors, formErrors));
    }
  };

  /**
   * Handle custom input for more complex controls.
   * @param {object} newValues
   */
  const updateValues = useCallback((newValues) => {
    setValues((oldValues) => ({ ...oldValues, ...newValues }));
  }, []);

  const loadData = (data) => {
    setValues(data);
  };

  /**
   * Removes an error
   * @param {string} errorToRemove
   */
  const removeError = (errorToRemove) => {
    setErrors((prevErrors) => {
      const { [errorToRemove]: _, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  };

  return {
    values,
    errors,
    setErrors,
    onChange,
    onSubmit,
    loadData,
    onBlur: onChange,
    onSwitch,
    updateValues,
    removeError,
  };
};

export function useFormInput(initValue = "") {
  const [value, setValue] = useState(initValue);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = useCallback(
    (_e, el) => {
      if (value !== el.value) {
        setValue(el.value);
      }
    },
    [value],
  );

  const handleError = useCallback(
    (message) => {
      if (message !== errorMessage) {
        setErrorMessage(message);
        setError(true);
      }
    },
    [errorMessage],
  );

  const resetError = useCallback(() => {
    if (error) {
      setErrorMessage("");
      setError(false);
    }
  }, [error]);

  const onBlur = useCallback(
    (e) => {
      if (e.target.checkValidity()) {
        resetError();
      } else {
        handleError(e.target.validationMessage);
      }
    },
    [handleError, resetError],
  );

  return {
    value,
    error,
    errorMessage,
    onBlur,
    onChange: handleChange,
    setError: handleError,
    resetError: resetError,
  };
}

/**
 * Determines if mouse click is a common gesture for the
 * “open in new tab” command (Ctrl/Cmd + Click or middle mouse button).
 * @param {React.MouseEvent} e
 */
export function isNewTabClick(e) {
  return e.button === 1 || e.metaKey || e.ctrlKey;
}

/**
 * Joins a string of CSS class names, ignoring false-y values.
 * @param  {...any} names
 */
export const classNames = (...names) => names.filter((x) => x).join(" ");

const MIN_SEARCH_SYMBOLS = 3;

/**
 * check if we should do a search
 * @param {string} query
 * @param {string} prev_query
 * @return {boolean}
 */
// biome-ignore lint/style/useNamingConvention: requires larger refactor
export const should_do_search = (query, prev_query) => {
  if (prev_query.length > 0 && query.length < 1) {
    return true;
  }

  return query.length >= MIN_SEARCH_SYMBOLS;
};

/**
 * handle the case when we had query but user clear the field
 * @param query
 * @param prev_query
 * @return {boolean}
 */
// biome-ignore lint/style/useNamingConvention: <server params>
export const search_query_becomes_empty = (query, prev_query) => {
  return prev_query.length > 0 && query.length < 1;
};

/**
 * Debounces a callback using a simple timer. useCallback is applied automatically.
 * @param {number} delay
 * @param {*} callback
 */
export const useDebounce = (delay, callback) => {
  const timerRef = useRef();
  return useCallback((...args) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => callback(...args), delay);
  });
};

/**
 * Checks if current component is mounted (e. g. to prevent unnecessary setState
 * calls). When possible, canceling in-flight requests on component unmount should
 * be preferred, but this is often not practical, and this hook can be used instead.
 *
 * @see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @returns {boolean}
 */
export const useMounted = () => {
  const isMounted = useRef(false);
  useLayoutEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return isMounted;
};

/**
 * handle max/maxlength for number type
 * @param {Event} e
 * @param {object} input
 */
export const handleNumber = (e, input) => {
  e.preventDefault();
  e.stopPropagation();

  if (input.value > input.max || input.value.length > input.max.length) {
    e.target.value = input.value.slice(0, -1);
  }
};

/**
 * remove banner
 */
export const onBannerDismiss = () => {
  const node = document.querySelector(".page-success-message");
  if (node) {
    node.remove();
  }
};
