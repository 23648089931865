import { useContext, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { Button, Form, Input, Message, Segment } from "semantic-ui-react";
import { OnlineContext } from "../../context/online-context";
import { useForm } from "../../libs/component_utils";
import PasswordsService from "../../services/passwords";
import LoginLayout from "../login-layout";

export default function ResetPasswordForm({ location }) {
  const { values, errors, onChange, onSubmit } = useForm(
    handleSubmit,
    {
      email: location.state?.email || "",
    },
    (_, { email }) =>
      email.endsWith("@mediamath.com")
        ? {
            globalError:
              "Your email's domain is part of an Enterprise identity provider. " +
              "To reset your password, please see your security administrator.",
          }
        : {},
  );

  const [serverError, setServerError] = useState("");
  const [finalMessage, setFinalMessage] = useState("");
  const globalError = errors.globalError || serverError;
  const [submitting, setSubmitting] = useState(false);
  const { online } = useContext(OnlineContext);
  const services = useRef(new Map([["passwords", new PasswordsService()]]));
  const intl = useIntl();

  async function handleSubmit(values) {
    /** @type PasswordsService */
    const service = services.current.get("passwords");

    setSubmitting(true);
    try {
      const response = await service.create(values);
      if (response.meta.status === "Error") {
        throw response;
      }

      setFinalMessage(
        intl.formatMessage({
          id: "BODY_RESET_PWD_SENT",
          defaultMessage: "We’ve just sent you an email to reset your password.",
        }),
      );
    } catch (e) {
      setServerError(`Error: ${e.message || e.error.message}`);
    } finally {
      setSubmitting(false);
    }
  }

  // User is already logged in, no need to show the form
  if (online) {
    return <></>;
  }

  if (finalMessage) {
    return (
      <LoginLayout>
        <div>{finalMessage}</div>
        <div
          align="center"
          style={{ marginTop: "20px" }}
        >
          <NavLink to="/">{intl.formatMessage({ id: "LINK_BACK_HOME", defaultMessage: "Back to Homepage" })}</NavLink>
        </div>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <Segment
        className="no-background"
        textAlign="left"
        style={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <h1>{intl.formatMessage({ id: "HEADING_FORGOTTEN_PWD", defaultMessage: "Forgotten password?" })}</h1>
        <p>
          {intl.formatMessage({
            id: "BODY_FORGOTTEN_PWD",
            defaultMessage: "Enter your email address to reset your password.",
          })}
        </p>
        {globalError && (
          <Message
            error
            size="tiny"
            content={globalError}
          />
        )}
      </Segment>
      <Segment className="no-background">
        <Form
          size="large"
          onSubmit={onSubmit}
          loading={submitting}
          noValidate
          error={!!Object.keys(errors).length}
          autoComplete="off"
        >
          <Form.Field
            align="left"
            error={Boolean(errors.email)}
          >
            <Input
              placeholder={intl.formatMessage({ id: "LABEL_EMAIL", defaultMessage: "Email" })}
              required
              name="email"
              type="email"
              value={values.email}
              onChange={onChange}
            />
            <div className="custom-error">{errors.email}</div>
          </Form.Field>
          <Form.Field className="top-margin-30">
            <Button
              fluid
              size="large"
              primary
            >
              {intl.formatMessage({ id: "BTN_RESET_PWD", defaultMessage: "Reset Password" })}
            </Button>
          </Form.Field>
        </Form>
      </Segment>
    </LoginLayout>
  );
}
