import { memo } from "react";
import { useIntl } from "react-intl";
import { Grid, Header } from "semantic-ui-react";
import "./index.css";

const SSOVerifyPage = memo(() => {
  const intl = useIntl();

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", color: "white", marginTop: 0 }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 500 }}>
        <div className="logo">&nbsp;</div>
        <Header
          as="h2"
          style={{ color: "white" }}
        >
          {intl.formatMessage({
            id: "HEADING_SSO",
            defaultMessage: "Verifying your account and logging you in...",
          })}
        </Header>
      </Grid.Column>
    </Grid>
  );
});

export default SSOVerifyPage;
