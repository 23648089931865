import { useCallback, useEffect, useState } from "react";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Message, Transition } from "semantic-ui-react";
import "./App.css";
import LocaleProvider from "./components/locale/provider";
import { LoginFormNew } from "./components/login";
import ResetPasswordForm from "./components/reset-password";
import NewPasswordForm from "./components/reset-password/new-password";
import MaintenancePage from "./components/static/maintenance";
import SSOErrorPage from "./components/static/sso-error";
import SSOVerifyPage from "./components/static/sso-verify";
import { Config } from "./config/api";
import { OnlineContext } from "./context/online-context";
import { isNil } from "./libs/common_utils";
import Registry from "./libs/register_storage";
import { useAuth0 } from "./reactAuth0Wrapper";

// TODO: refactor into utility function. Don't change native objects, EVER!
String.prototype.format = function (...args) {
  if (!this.match(/^(?:(?:(?:[^{}]|(?:{{)|(?:}}))+)|(?:{[0-9]+}))+$/)) {
    throw new Error("invalid format string.");
  }
  return this.replace(/((?:[^{}]|(?:{{)|(?:}}))+)|(?:{([0-9]+)})/g, (_, str, index) => {
    if (str) {
      return str.replace(/(?:{{)|(?:}})/g, (m) => m[0]);
    }
    if (index >= args.length) {
      throw new Error("argument index is out of range in format");
    }
    return args[index];
  });
};
const msgStyle = { width: "350px", marginTop: 0, right: "0", position: "fixed", top: "0", zIndex: 1000 };

const AppLogin = () => {
  const storage = new Registry(window.localStorage);
  const [online, setOnline] = useState(storage.hasItem("token"));
  const [serverError, setServerError] = useState();
  const { loading: auth0Loading, isAuthenticated } = useAuth0();

  const resetError = useCallback(() => {
    setServerError(null);
  }, []);

  /**
   * checking for auth0 valid token
   */
  // biome-ignore lint/correctness/useExhaustiveDependencies: only update on online change
  useEffect(() => {
    if ((!auth0Loading && isAuthenticated) || online) {
      window.location.replace(`${Config.basename}/campaigns`.replace(/\/{2,}/, "/"));
    }
  }, [online]);

  return (
    <LocaleProvider>
      <OnlineContext.Provider value={{ online, setOnline }}>
        <Transition
          visible={!isNil(serverError)}
          onComplete={() => setTimeout(resetError, 5000)}
          animation="scale"
          duration={500}
        >
          <Message
            error
            onClick={resetError}
            onDismiss={resetError}
            style={msgStyle}
          >
            {serverError}
          </Message>
        </Transition>
        <Router basename={Config.basename}>
          <Switch>
            <Route
              exact={true}
              path="/login"
              render={(router) => <LoginFormNew {...router} />}
            />
            <Route
              component={ResetPasswordForm}
              exact={true}
              path="/forgotten-password"
            />
            <Route
              component={NewPasswordForm}
              exact={true}
              path="/reset-password/:token"
              title={["Choose a new password"]}
            />
            <Route
              component={MaintenancePage}
              exact={true}
              path="/maintenance"
            />
            <Route
              component={SSOVerifyPage}
              exact={true}
              path="/sso-verify"
            />
            <Route
              component={SSOErrorPage}
              exact={true}
              path="/sso-error"
            />
            <Redirect
              from="*"
              to="/login"
            />
          </Switch>
        </Router>
      </OnlineContext.Provider>
    </LocaleProvider>
  );
};

export default AppLogin;
