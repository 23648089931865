import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import ReactDOM from "react-dom";
import AppLogin from "./AppLogin";
import { Config } from "./config/api.js";
import { Auth0Provider } from "./reactAuth0Wrapper";

import "semantic-ui-css/semantic.min.css";
import "./config.css";
import "./index.css";

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  const url = appState?.targetUrl || window.location.pathname;
  window.history.replaceState({}, document.title, url);
};

const redirectUrl = `${window.location.origin}${Config.basename}`;

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    user: { key: Config.public_client },
  });

  ReactDOM.render(
    <Auth0Provider
      domain={Config.auth0.domain}
      client_id={Config.auth0.clientId}
      redirect_uri={redirectUrl}
      onRedirectCallback={onRedirectCallback}
    >
      <LDProvider>
        <AppLogin />
      </LDProvider>
    </Auth0Provider>,
    document.getElementById("root"),
  );
})();
