import { useEffect, useRef, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { Button, Form, Input, Loader, Message, Segment } from "semantic-ui-react";
import LoginLayout from "../login-layout";

import { useIntl } from "react-intl";
import { useForm } from "../../libs/component_utils";
import PasswordsService from "../../services/passwords";

export default function NewPasswordForm() {
  const { token } = useParams();
  /** @type History */
  const history = useHistory();
  const { values, errors, onChange, onSubmit } = useForm(
    handleSubmit,
    {
      token,
      password: "",
      "confirm-password": "",
    },
    (_, values) => {
      const errors = {};
      if (values["confirm-password"] !== values.password) {
        errors["confirm-password"] = intl.formatMessage({
          id: "ERROR_PWD_CONFIRM",
          defaultMessage: "Passwords do not match",
        });
      }
      return errors;
    },
  );
  const [serverError, setServerError] = useState("");
  const [finalMessage, setFinalMessage] = useState("");
  const globalError = errors.globalError || serverError;
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const services = useRef(new Map([["passwords", new PasswordsService()]]));
  const intl = useIntl();

  async function handleSubmit(values) {
    /** @type PasswordsService */
    const service = services.current.get("passwords");

    setSubmitting(true);
    try {
      const response = await service.reset(values);
      if (response.meta.status === "Error") {
        throw response;
      }

      history.replace("/login", {
        action: "reset",
        username: email,
      });
    } catch (e) {
      setServerError(
        intl.formatMessage(
          {
            id: "ERROR_GENERIC_PREFIX",
            defaultMessage: "Error: {message}",
          },
          { message: e.message || e.error.message },
        ),
      );
    } finally {
      setSubmitting(false);
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    (async () => {
      /** @type PasswordsService */
      const service = services.current.get("passwords");
      try {
        const { data } = await service.validate(token);
        setEmail(data.email);
      } catch (e) {
        if (e.message || e.error.message) {
          setFinalMessage(
            `${intl.formatMessage({
              id: "BODY_NEW_PASSWORD_ERROR",
              defaultMessage: "Cannot set a new password at this time",
            })}: ${e.message || e.error.message}`,
          );
        } else {
          setFinalMessage(
            intl.formatMessage({
              id: "BODY_NEW_PASSWORD_ERROR",
              defaultMessage: "Cannot set a new password at this time",
            }),
          );
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <LoginLayout>
        <Loader
          active
          inverted
        />
      </LoginLayout>
    );
  }

  if (finalMessage) {
    return (
      <LoginLayout>
        <div>
          <p>{finalMessage}</p>
        </div>
        <div
          align="center"
          style={{ marginTop: "20px" }}
        >
          <NavLink to="/">{intl.formatMessage({ id: "LINK_BACK_HOME", defaultMessage: "Back to Homepage" })}</NavLink>
        </div>
      </LoginLayout>
    );
  }

  return (
    <LoginLayout>
      <Segment
        className="no-background"
        textAlign="left"
        style={{ marginBottom: 0, paddingBottom: 0 }}
      >
        <h1>{intl.formatMessage({ id: "HEADING_NEW_PWD", defaultMessage: "Choose a new password" })}</h1>
        <p>{intl.formatMessage({ id: "BODY_NEW_PWD", defaultMessage: "Hi, {email}" }, { email })}</p>
        {globalError && (
          <Message
            error
            size="tiny"
            content={globalError}
          />
        )}
      </Segment>
      <Form
        size="large"
        onSubmit={onSubmit}
        loading={submitting}
        noValidate
        error={!!Object.keys(errors).length}
        autoComplete="off"
      >
        <Segment className="no-background">
          <Form.Field
            align="left"
            error={Boolean(errors.password)}
          >
            <label>{intl.formatMessage({ id: "LABEL_PASSWORD", defaultMessage: "Password" })}</label>
            <Input
              required
              name="password"
              type="password"
              value={values.password}
              onChange={onChange}
            />
            <div className="custom-error">{errors.password}</div>
          </Form.Field>
          <Form.Field
            align="left"
            error={Boolean(errors["confirm-password"])}
          >
            <label>{intl.formatMessage({ id: "LABEL_CONFIRM", defaultMessage: "Confirm" })}</label>
            <Input
              required
              name="confirm-password"
              type="password"
              value={values["confirm-password"]}
              onChange={onChange}
            />
            <div className="custom-error">{errors["confirm-password"]}</div>
          </Form.Field>
          <Form.Field className="top-margin-30">
            <Button
              fluid
              size="large"
              primary
            >
              {intl.formatMessage({ id: "BTN_SET_PWD", defaultMessage: "Set Password" })}
            </Button>
          </Form.Field>
        </Segment>
      </Form>
    </LoginLayout>
  );
}
