import { useFlags } from "launchdarkly-react-client-sdk";
import { Grid } from "semantic-ui-react";
import LocalePicker from "../locale/picker";
import "./index.css";

export default function LoginLayout({ children }) {
  const { whitelabelShowTranslation } = useFlags();
  return (
    <>
      {whitelabelShowTranslation && (
        <div className="login-language-picker">
          <LocalePicker
            button
            floating
            className="primary"
            direction="left"
          />
        </div>
      )}
      <div className="login-form">
        <div className="logo">&nbsp;</div>
        <Grid textAlign="center">
          <Grid.Column>{children}</Grid.Column>
        </Grid>
      </div>
    </>
  );
}
