import { memo } from "react";
import { useIntl } from "react-intl";
import { Grid, Header } from "semantic-ui-react";
import "./index.css";
import { Config } from "../../config/api";
import { getReadableClientName } from "../../libs/common_utils";

const SSOErrorPage = memo(() => {
  const intl = useIntl();
  const login_page = `${Config.basename}/login`.replace(/\/{2,}/, "/");

  const clientName = getReadableClientName(Config.public_client);

  const message = intl.formatMessage(
    {
      id: "HEADING_SSO_ERROR",
      defaultMessage:
        "Verifying your account failed. Please try again to login or speak to your {client_name} representative",
    },
    { client_name: clientName },
  );

  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh", color: "white", marginTop: 0 }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 500 }}>
        <div className="logo">&nbsp;</div>
        <Header
          as="h2"
          style={{ color: "white" }}
        >
          {message.slice(0, message.indexOf("login"))}
          <a
            href={login_page}
            style={{ color: "white", textDecoration: "underline" }}
          >
            {intl.formatMessage({ id: "login", defaultMessage: "login" })}
          </a>
          {message.slice(message.indexOf("login") + 5)}
        </Header>
      </Grid.Column>
    </Grid>
  );
});

export default SSOErrorPage;
