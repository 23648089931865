export const currencySymbols = Object.freeze({
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong,
  CAD: "$", // Canadian Dollar
  AED: "د.إ", // UAE Dirham
  ARS: "$", // Argentine Peso
  AUD: "$", // Australian Dollar
  BGN: "лв", // Bulgarian Lev
  BRL: "R$", // Brazilian Real
  CHF: "₣", // Swiss Franc
  CLP: "$", // Chilean Peso
  CNY: "¥", // Yuan
  COP: "$", // Colombian Peso
  CZK: "Kč", // Czech Koruna
  DKK: "kr", // Danish Krone
  EGP: "£", // Egyptian Pound
  FJD: "$", // Fiji Dollar
  HKD: "$", // Hong Kong Dollar
  HUF: "Ft", // Forint
  IDR: "Rp", // Rupiah
  ISK: "Kr", // Iceland Krona
  JMD: "$", // Jamaican Dollar
  JOD: "د.ا", // Jordanian Dinar
  KWD: "د.ك", // Kuwaiti Dinar
  MAD: "د.م.", // Moroccan Dirham
  MXN: "$", // Mexican Peso
  MYR: "RM", // Malaysian Ringgit
  NOK: "kr", // Norwegian Krone
  NZD: "$", // New Zealand Dollar
  PEN: "S/.", // Nuevo Sol
  PKR: "₨", // Pakistan Rupee
  QAR: "ر.ق", // Qatari Rial
  RUB: "р.", // Russian Ruble
  SAR: "ر.س", // Saudi Riyal
  SEK: "kr", // Swedish Krona
  SGD: "$", // Singapore Dollar
  TRY: "₤", // Turkish Lira
  TWD: "$", // Taiwan Dollar
  VEB: "Bs.", // Venezuelan Bolivar
  ZAR: "R", // Rand
});
