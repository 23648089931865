import { useCallback, useContext, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Form, Input, Message, Segment } from "semantic-ui-react";
import LoginLayout from "../login-layout";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useIntl } from "react-intl";
import { OnlineContext } from "../../context/online-context";
import { useForm } from "../../libs/component_utils";
import Registry from "../../libs/register_storage";
import AuthService from "../../services/auth";

const REDIR_MESSAGES = {
  reset: "Password has been reset!",
};

export function LoginFormNew() {
  const location = useLocation();
  const intl = useIntl();

  const { values, errors, onChange, onSubmit } = useForm(
    handleSubmit,
    {
      username: location.state?.username || "",
      password: "",
    },
    () => {
      return {};
    },
  );

  const storage = new Registry(window.localStorage);

  const [serverError, setServerError] = useState("");
  const [loading, setLoading] = useState(false);
  const { online, setOnline } = useContext(OnlineContext);
  const services = useRef(new Map([["auth", new AuthService()]]));

  const { whitelabelShowSsoLoginFlag } = useFlags();

  const ssoLoginUrl = process.env.REACT_APP_SSO_LOGIN_URL;

  const handleSsoLogin = useCallback(() => {
    window.location.href = ssoLoginUrl;
  }, [ssoLoginUrl]);

  /**
   * register values in storage
   * @param {string} token
   * @param {string} username
   * @param {number} org_id
   */
  const doLogin = (token, username, org_id, is_admin, user_role, user_type, user_id) => {
    storage.setItem("token", token);
    storage.setItem("username", username);
    storage.setItem("organization", org_id);
    storage.setItem("is_admin", is_admin);
    storage.setItem("user_role", user_role);
    storage.setItem("user_type", user_type);
    storage.setItem("user_id", user_id);
    setOnline(true);
  };

  function handleSubmit(values) {
    const auth = services.current.get("auth");
    (async () => {
      try {
        setLoading(true);
        const response = await auth.login({
          username: values.username,
          password: values.password,
        });
        if (response.status === "Error") {
          throw response;
        }

        doLogin(
          response.data.token,
          response.data.username,
          response.data.org_id,
          response.data.is_admin,
          response.data.user_role,
          response.data.user_type,
          response.data.user_id,
        );
      } catch (e) {
        setServerError(`${e.message || e.error.message}`);
        setLoading(false);
      }
    })();
  }

  // User is already logged in, no need to show the form
  if (online) {
    return <></>;
  }

  return (
    <LoginLayout>
      <Segment
        className="no-background"
        textAlign="left"
        style={{ marginBottom: "0" }}
      >
        <Message
          data-testid="success-message"
          success
          hidden={!location.state?.action}
          size="tiny"
          content={REDIR_MESSAGES[location.state?.action]}
        />
        <Message
          data-testid="failed-login-message"
          error
          hidden={!serverError}
          size="tiny"
          content={serverError}
        />
      </Segment>
      <Form
        size="large"
        onSubmit={onSubmit}
        loading={loading}
        noValidate
        error={!!Object.keys(errors).length}
        autoComplete="off"
        style={{ marginBottom: "0px" }}
      >
        <Segment
          className="no-background"
          style={{ marginBottom: "0px !important" }}
        >
          <Form.Field
            align="left"
            error={Boolean(errors.username)}
          >
            <label>
              {intl.formatMessage({
                id: "LABEL_USERNAME",
                defaultMessage: "Username",
              })}
            </label>
            <Input
              placeholder={intl.formatMessage({
                id: "LABEL_USERNAME",
                defaultMessage: "Username",
              })}
              required
              name="username"
              value={values.username}
              onChange={onChange}
            >
              <input data-testid="username-input" />
            </Input>
            <div
              data-testid="please-fill-username"
              className="custom-error"
            >
              {errors.username}
            </div>
          </Form.Field>
          <Form.Field error={Boolean(errors.password)}>
            <label>
              {intl.formatMessage({
                id: "LABEL_PASSWORD",
                defaultMessage: "Password",
              })}
            </label>
            <Input
              placeholder={intl.formatMessage({
                id: "LABEL_PASSWORD",
                defaultMessage: "Password",
              })}
              minLength="10"
              required
              type="password"
              name="password"
              value={values.password}
              onChange={onChange}
            >
              <input
                autoComplete="password"
                data-testid="password-input"
              />
            </Input>
            <div
              data-testid="please-fill-password"
              className="custom-error"
            >
              {errors.password}
            </div>
          </Form.Field>
          <Form.Field className="top-margin-50">
            <Button
              fluid
              size="large"
              primary
              data-testid="login-button"
            >
              {intl.formatMessage({
                id: "BTN_LOGIN",
                defaultMessage: "Log In",
              })}
            </Button>
          </Form.Field>
          <Form.Field style={{ marginTop: "20px !important", marginBottom: "0px !important" }}>
            <Link
              to={{
                pathname: "/forgotten-password",
                state: { email: values.username },
              }}
              data-testid="forgotten-password"
            >
              {intl.formatMessage({
                id: "LINK_FORGOTTEN_PWD",
                defaultMessage: "Forgotten password?",
              })}
            </Link>
          </Form.Field>
        </Segment>
      </Form>
      {whitelabelShowSsoLoginFlag ? (
        <Segment
          className="no-background"
          style={{ marginTop: "0px" }}
        >
          <Button
            data-testid="employee-login"
            type="reset"
            fluid
            size="large"
            style={{
              backgroundColor: "rgb(255, 194, 32)",
              color: "rgb(255, 255, 255)",
              marginTop: 0,
            }}
            onClick={handleSsoLogin}
          >
            {intl.formatMessage({
              id: "SSO_BTN_LOGIN",
              defaultMessage: "Walmart Employee Login",
            })}
          </Button>
        </Segment>
      ) : null}
    </LoginLayout>
  );
}
